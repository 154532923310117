import { useEffect } from "react";
import {
  ChakraProvider,
  Heading,
  Box,
  Text,
  Image,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
// import { GrDocumentText } from "react-icons/gr";
import ReactGA from 'react-ga4';

export const HowToUsePage = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/test_cases", title: "TestCasesPage" });
  }, []);

  return (
    <ChakraProvider>
        <Navbar />
      <Box 
        p={4} 
        width={{sm:"95vw", md:"70vw"}} 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        // alignItems="center" 
        mx="auto" 
        mt={10}
        // border={"2px solid red"}
      >
        <Heading 
          as="h1" 
          size="4xl" 
          color="teal" 
          textAlign="center" 
          mb={{ base: 20, md: 20 }}
        >
          How to Use JudgeAI for Arbitration
        </Heading>
        <Text fontSize="xl">
          Follow these easy steps to conduct arbitration with JudgeAI.
        </Text>

        <Heading size={"md"} mt={4}>
          1. Start Arbitration
        </Heading>
        <UnorderedList fontSize="xl" ml={10}>
            <ListItem>Go to the arbitration section and click on the <Text as='b'>Arbitration</Text> button.</ListItem>
            <ListItem>
                A modal will appear, where you can choose how to proceed:
            </ListItem>
            <ListItem>Click on <Text as='b'>Conduct Individually</Text>:</ListItem>
        </UnorderedList>
        {/* <Box boxSize='4xl'> */}
            <Image src='guide/main_1.gif' alt='Dan Abramov' width={{sm:"95vw", md:"50vw"}} mb={10} mt={5}/>
        {/* </Box> */}

        <Heading size={"md"} mt={4}>
        2. Submit Your Document
        </Heading>
        <UnorderedList fontSize="xl" ml={10}>
            <ListItem>
                You’ll be redirected to the arbitration field.
            </ListItem>
            <ListItem>
                To upload your document, <Text as='b'>click the arrow icon</Text>, select your file, and then click <Text as='b'>Submit</Text>
            </ListItem>
            <ListItem>
                JudgeAI will begin analyzing the facts. This process usually takes less than a minute.
            </ListItem>
        </UnorderedList>
        <Image src='guide/main_2.gif' alt='Dan Abramov' width={{sm:"95vw", md:"50vw"}} mb={10} mt={5}/>


    <Heading size={"md"} mt={4}>
    3. Submit Defendant’s Document
    </Heading>
    <UnorderedList fontSize="xl" ml={10}>
    <ListItem>
        Once JudgeAI has processed your document, you’ll be prompted to submit the defendant’s document.
    </ListItem>
    <ListItem>
        <Text as='b'>Click the arrow icon</Text> to upload the document and then click <Text as='b'>Submit</Text>.
    </ListItem>
    </UnorderedList>
    <Image src='guide/main_3.gif' alt='Dan Abramov' width={{sm:"95vw", md:"50vw"}} mb={10} mt={5}/>



    <Heading size={"md"} mt={4}>
    4. Handle Objections
    </Heading>
    <UnorderedList fontSize="xl" ml={10}>
    <ListItem>
        After analyzing the defendant’s document, JudgeAI will give you the option to upload additional documents if you have objections.
    </ListItem>
    <ListItem>
        If you have objections:
        <UnorderedList>
        <ListItem>
            Upload the relevant files following the same steps as before.
        </ListItem>
        <ListItem>
        If you have no objections, simply click the “No Objection” button to proceed.
    </ListItem>
        </UnorderedList>
    </ListItem>
    </UnorderedList>
    <Image src='guide/main_4.gif' alt='Dan Abramov' width={{sm:"95vw", md:"50vw"}} mb={10} mt={5}/>


    <Heading size={"md"} mt={4}>
    5. Final Step
    </Heading>
    <Text fontSize="xl">
    Once all information is submitted and analyzed, JudgeAI will finalize its decision based on the provided details.
    </Text>
    <Image src='guide/main_5.gif' alt='Dan Abramov' width={{sm:"95vw", md:"50vw"}} mb={10} mt={5}/>
      </Box>
    </ChakraProvider>
  );
}

export default HowToUsePage;
