// import { Box, Button } from '@chakra-ui/react';
// import { InfoIcon } from '@chakra-ui/icons';

// interface Props {
//   guideUrl: string; // Single URL for the guide
// }

// const GuideButton = ({ guideUrl }: Props) => {
//   return (
//     <Box 
//     position="fixed"
//     right={20}
//     p={5}
//     w="200px"
//     top={120}
//     h="100%"
//     >
        
//       <Button
//         leftIcon={<InfoIcon />}
//         as="a"
//         href={guideUrl}
//         w="100%"
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         Open Guide
//       </Button>
//     </Box>
//   );
// };

// export default GuideButton;


import { Box, Button, IconButton } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

interface Props {
  guideUrl: string; // Single URL for the guide
}

const GuideButton = ({ guideUrl }: Props) => {
  return (
    <Box
      position="fixed"
      right={{ base: 0, md: "1vw" }} // Adjust position for small and larger screens
      p={5}
      w={{ base: "auto", md: "200px" }} // Icon only for small screens
      top={{base: 50, md: 120}}
      h="auto"
    >
      {/* Show only icon on small screens, full button with text on larger screens */}
      <Box display={{ base: "none", md: "block" }}>
        <Button
          leftIcon={<InfoIcon />}
          as="a"
          href={guideUrl}
          w="100%"
          target="_blank"
          rel="noopener noreferrer"
        >
          Guide
        </Button>
      </Box>
      <Box display={{ base: "block", md: "none" }}>
        <IconButton
        size={"sm"}
          icon={<InfoIcon />}
          as="a"
          href={guideUrl}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Open Guide"
        />
      </Box>
    </Box>
  );
};

export default GuideButton;
