import { useState, useEffect } from "react";
import {
  ChakraProvider,
  theme,
  Box,
  VStack,
  Button,
  Text,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  Heading,
  useToast,
  Progress
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import UploadButton from "../components/UploadButton";
import axios from 'axios';
import Markdown from "react-markdown";
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import ReactMarkdown from "react-markdown";
import { useUserAuth } from "../context/userAuthContext";
import { db, storage } from '../firebase';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ReactGA from 'react-ga4';
import GuideButton from "../components/GuideButton";

interface ClaimInfo {
  claimant: string;
  defendant: string;
  date_of_claim_firing: string;
  amount_of_claim: string;
  claim_validity: string;
  principal_circumstances: string;
  derivative_circumstances: string;
  indirectly_derivative_circumstances: string;
  ancillary_circumstances: string;
  categories_of_circumstances: string[];
  most_important_circumstance: string;
  evidences_indicated: string[];
  related_facts: string[];
}

interface ResponseInfo {
  response_validity: string;
  claim_needs_rechecking: string;
  prima_facie_refuted: string;
  principal_circumstances: string;
  derivative_circumstances: string;
  indirectly_derivative_circumstances: string;
  ancillary_circumstances: string;
  categories_of_circumstances: string[];
  most_important_circumstance: string;
  evidences_indicated_by_claimant: string[];
  related_facts_to_evidence_by_claimant: string[];
  evidences_indicated_by_defendant: string[];
  related_facts_to_evidence_by_defendant: string[];
}

interface EvidenceEvaluationInfo {
  evidence_claimant_descriptions: string[];
  evidence_claimant_strengths: string[];
  related_facts_to_evidence_by_claimant: string[];
  evidence_defendant_descriptions: string[];
  evidence_defendant_strengths: string[];
  related_facts_to_evidence_by_defendant: string[];
}

interface EvidenceLinkageInfo {
  descriptions_of_plaintiff_evidence: string[];
  direct_facts_to_plaintiff_evidence: string[];
  descriptions_of_defendant_evidence: string[];
  direct_facts_to_defendant_evidence: string[];
  selections: string[];
  description_of_detailed_facts: string[];
  contradictory_facts: string[];
}

interface CaseFableInfo {
  events: string[];
  dates: string[];
  participants: string[];
  locations: string[];
  pure_chronology_facts_with_events_dates_participants_locations_in_a_wellformed_sentence: string[];
}

// Define the IdealChronologyInfo interface
interface IdealChronologyInfo {
  preparation_phase_event: string;
  preparation_phase_action_party_A: string;
  preparation_phase_action_party_B: string;
  initial_phase_of_obligation_fulfillment_event: string;
  initial_phase_of_obligation_fulfillment_action_party_A: string;
  initial_phase_of_obligation_fulfillment_action_party_B: string;
  intermediate_actions_event: string;
  intermediate_action_party_A: string;
  intermediate_action_party_B: string;
  ensuring_external_conditions_event: string;
  ensuring_external_conditions_action_party_A: string;
  ensuring_external_conditions_action_party_B: string;
  completion_of_obligations_event: string;
  completion_of_obligations_action_party_A: string;
  completion_of_obligations_action_party_B: string;
  final_phase_event: string;
  final_phase_action_party_A: string;
  final_phase_action_party_B: string;
}

export const JudgementPage = () => {
  const { user } = useUserAuth();

  const [claimInfo, setClaimInfo] = useState<ClaimInfo | null>(null);
  const [responseInfo, setResponseInfo] = useState<ResponseInfo | null>(null);
  const [evidenceEvaluationInfo, setEvidenceEvaluationInfo] = useState<EvidenceEvaluationInfo | null>(null);
  const [evidenceLinkageInfo, setEvidenceLinkageInfo] = useState<EvidenceLinkageInfo | null>(null);
  const [caseFableInfo, setCaseFableInfo] = useState<CaseFableInfo | null>(null);
  const [idealChronologyInfo, setIdealChronologyInfo] = useState<IdealChronologyInfo  | null>(null);

  const [stage1Files, setStage1Files] = useState<File[]>([]);
  const [stage1Output, setStage1Output] = useState<string>("");
  const [stage1SubmitLoading, setStage1SubmitLoading] = useState(false)
  const [stage1SubmitDisabled, setStage1SubmitDisabled] = useState(false)
  const [stage1FileUploading, setStage1FileUploading] = useState(false)

  const [showStage2, setShowStage2] = useState(false)
  const [stage2Files, setStage2Files] = useState<File[]>([]);
  const [stage2Output, setStage2Output] = useState<string>("");
  const [stage2SubmitLoading, setStage2SubmitLoading] = useState(false)
  const [stage2SubmitDisabled, setStage2SubmitDisabled] = useState(false)
  const [stage2FileUploading, setStage2FileUploading] = useState(false)
  
  const [showStage3, setShowStage3] = useState(false);
  const [stage3Files, setStage3Files] = useState<File[]>([]);
  const [stage3Output, setStage3Output] = useState<string>("");
  const [stage3SubmitLoading, setStage3SubmitLoading] = useState(false)
  const [stage3SubmitDisabled, setStage3SubmitDisabled] = useState(false)
  const [stage3FileUploading, setStage3FileUploading] = useState(false)

  const [showStage4, setShowStage4] = useState(false);
  const [stage4Files, setStage4Files] = useState<File[]>([]);
  const [stage4Output, setStage4Output] = useState<string>("");
  const [stage4SubmitLoading, setStage4SubmitLoading] = useState(false)
  const [stage4SubmitDisabled, setStage4SubmitDisabled] = useState(false)
  const [stage4FileUploading, setStage4FileUploading] = useState(false)

  const [claimExtractedText, setClaimExtractedText] = useState<string>("");
  const [legalNormsExtractedText, setLegalNormsExtractedText] = useState<string>("");
  // const [evidenceExtractedText, setEvidenceExtractedText] = useState<string>("");
  const [defendantExtractedText, setDefendantExtractedText] = useState<string>("");
  const [claimantResponseExtractedText, setClaimantResponseExtractedText] = useState<string>("");
  const [defendantResponseExtractedText, setDefendantResponseExtractedText] = useState<string>("");

  const [stageo_1Output, setStageo_1Output] = useState<string>("");

  const [stageo_2Output, setStageo_2Output] = useState<string>("");
  
  const [stage4_9Output, setStage4_9Output] = useState<string>("");

  const [stage4_9_5Output, setStage4_9_5Output] = useState<string>("");

  const [stage5Output, setStage5Output] = useState<string>("");

  const [stage5_9Output, setStage5_9Output] = useState<string>("");

  const [stage5_9_5Output, setStage5_9_5Output] = useState<string>("");

  const [stage5_9_6Output, setStage5_9_6Output] = useState<string>("");

  const [showStage6, setShowStage6] = useState(false);
  const [stage6Output, setStage6Output] = useState<string>("");

  const [showStage7, setShowStage7] = useState(false);
  const [stage7Output, setStage7Output] = useState<string>("");
  const [stage7_1Output, setStage7_1Output] = useState<string>("");
  const [stage7_2Output, setStage7_2Output] = useState<string>("");

  const [downloadReportSubmitLoading, setDownloadReportSubmitLoading] = useState(false)

  const [userCredits, setUserCredits] = useState(0);
  const [userEmail, setUserEmail] = useState("");
  const [loadingScreen, setLoadingScreen] = useState(true);

  const [progress, setProgress] = useState(0); // Progress bar value

  const toast = useToast()

  function addToast() {
    // toast({ description: 'some text' })
    toast({
      title: 'JudgeAI is analysing facts',
      description: "JudgeAI typically takes a minute to respond",
      status: 'loading',
      duration: null,
      // isClosable: true,
    })
  }

  function closeAll() {
    toast.closeAll()
  }
  
  // useEffect for Google Analytics pageview tracking
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/judgement", title: "JudgementPage" });
  }, []); // Empty dependency array means this runs only on mount

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) { 
        const userEmail = user.email || '';
        setUserEmail(userEmail);
          
        try {
            const userDoc = await getDoc(doc(db, "users", userEmail));
            if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserCredits(userData.credits || 0);
            setLoadingScreen(false)
            } else {
            console.log("No such document!");
            }
        } catch (error) {
            console.error("Error fetching user data: ", error);
        }
        }
    };

    // ReactGA.send({ hitType: "pageview", page: "/judgement", title: "JudgementPage" });
    fetchUserData();
    }, [user]);


  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  useEffect(() => {
    if (stage1Output) {
      scrollToSection("bottom");
      setProgress(10)
    }
  }, [stage1Output]);
  
  useEffect(() => {
    if (stage2Output) {
      scrollToSection("bottom");
      setProgress(20)
    }
  }, [stage2Output]);
  
  useEffect(() => {
    if (showStage3) {
      scrollToSection("bottom");
      setProgress(25)
    }
  }, [showStage3]);

  useEffect(() => {
    if (showStage4) {
      scrollToSection("bottom");
      setProgress(30)
    }
  }, [showStage4]);

  useEffect(() => {
    if (stage3Output) {
      scrollToSection("bottom");
    }
  }, [stage3Output]);
  
  useEffect(() => {
    if (stage4Output) {
      scrollToSection("bottom");
    }
  }, [stage4Output]);
  
  useEffect(() => {
    if (stage4_9Output) {
      scrollToSection("bottom");
      setProgress(40)
    }
  }, [stage4_9Output]);
  
  useEffect(() => {
    if (stage4_9_5Output) {
      scrollToSection("bottom");
      setProgress(50)
    }
  }, [stage4_9_5Output]);

  useEffect(() => {
    if (stageo_1Output) {
      scrollToSection("bottom");
      setProgress(55)
    }
  }, [stageo_1Output]);


  useEffect(() => {
    if (stageo_2Output) {
      scrollToSection("bottom");
      setProgress(60)
    }
  }, [stageo_2Output]);


  useEffect(() => {
    if (stage5Output) {
      scrollToSection("bottom");
      setProgress(70)
    }
  }, [stage5Output]);
  
  useEffect(() => {
    if (stage5_9Output) {
      scrollToSection("bottom");
      setProgress(75)
    }
  }, [stage5_9Output]);

  useEffect(() => {
    if (stage5_9_5Output) {
      scrollToSection("bottom");
      setProgress(80)
    }
  }, [stage5_9_5Output]);

  useEffect(() => {
    if (stage5_9_6Output) {
      scrollToSection("bottom");
    }
  }, [stage5_9_6Output]);
  
  useEffect(() => {
    if (stage6Output) {
      scrollToSection("bottom");
      setProgress(90)
    }
  }, [stage6Output]);
  
  useEffect(() => {
    if (stage7Output) {
      scrollToSection("bottom");
      setProgress(100)
    }
  }, [stage7Output]);
    

  const uploadFilesToFirebase = async (files: File[], stage: number) => {
    const uploadedFiles: { fileName: string; url: string }[] = [];

    for (const file of files) {
      const fileRef = ref(storage, `judgement_${userEmail}_${stage}_${file.name}`);
      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);
      uploadedFiles.push({ fileName: file.name, url });
    }

    return uploadedFiles;
  };


  const generateReport = async () => {
    // Uncomment
    // setDownloadReportSubmitLoading(true);

    const paragraphs = [
      stage1Output + stage3Output, 
      stage2Output + stage4Output,
      stage4_9Output + stage4_9_5Output,
      stage5Output,
      // stage5_9Output,
      // stage5_9_5Output,
      stage6Output,
      stage7Output,
      stage7_1Output,
      stage7_2Output,
  ];

    // const response = await fetch("https://judgeaiv0.azurewebsites.net/create-pdf-report/", {
    //     method: "POST",
    //     headers: {
    //         "Content-Type": "application/json"
    //     },
    //     body: JSON.stringify({ paragraphs })
    // });
    console.log(paragraphs)
    const response = await fetch("https://judgeaiv0.azurewebsites.net/create-pdf-report/", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        // body: JSON.stringify({ paragraphs, claim_info: claimInfo })
        body: JSON.stringify({ 
          paragraphs: {paragraphs:paragraphs}, 
          claim_info: claimInfo,
          response_info: responseInfo,
          evidence_evaluation_info: evidenceEvaluationInfo,
          evidence_linkage_info: evidenceLinkageInfo,
          case_fable_info: caseFableInfo,
          ideal_chronology_info: idealChronologyInfo
        })
    });

    if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "report.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
    } else {
        alert("Failed to generate report");
    }
    setDownloadReportSubmitLoading(false)
  };

  const handleStage1FilesChange = async (files: File[]) => {
    setStage1SubmitDisabled(true);
    setStage1SubmitLoading(true);
    setStage1FileUploading(true);
    
    try {
      const uploadedFiles = await uploadFilesToFirebase(files, 1);
      setStage1Files(files);
      console.log(uploadedFiles);
    } catch (error) {
      console.error("File upload failed", error);
    } finally {
      setStage1SubmitDisabled(false);
      setStage1SubmitLoading(false);
      setStage1FileUploading(false);
    }
  };

  const handleStage2FilesChange = async (files: File[]) => {
    setStage2SubmitDisabled(true);
    setStage2SubmitLoading(true);
    setStage2FileUploading(true);
  
    try {
      const uploadedFiles = await uploadFilesToFirebase(files, 2);
      setStage2Files(files);
      console.log(uploadedFiles);
    } catch (error) {
      console.error("File upload failed", error);
    } finally {
      setStage2SubmitDisabled(false);
      setStage2SubmitLoading(false);
      setStage2FileUploading(false);
    }
  };
  
  const handleStage3FilesChange = async (files: File[]) => {
    setStage3SubmitDisabled(true);
    setStage3SubmitLoading(true);
    setStage3FileUploading(true);
  
    try {
      const uploadedFiles = await uploadFilesToFirebase(files, 3);
      setStage3Files(files);
      console.log(uploadedFiles);
    } catch (error) {
      console.error("File upload failed", error);
    } finally {
      setStage3SubmitDisabled(false);
      setStage3SubmitLoading(false);
      setStage3FileUploading(false);
    }
  };
  
  const handleStage4FilesChange = async (files: File[]) => {
    setStage4SubmitDisabled(true);
    setStage4SubmitLoading(true);
    setStage4FileUploading(true);
  
    try {
      const uploadedFiles = await uploadFilesToFirebase(files, 4);
      setStage4Files(files);
      console.log(uploadedFiles);
    } catch (error) {
      console.error("File upload failed", error);
    } finally {
      setStage4SubmitDisabled(false);
      setStage4SubmitLoading(false);
      setStage4FileUploading(false);
    }
  };
  

  const handleSubmitStage1 = async () => {
    addToast()
    setStage1SubmitLoading(true)

    const userRef = doc(db, "users", userEmail);

    setUserCredits(userCredits - 1)

    await updateDoc(userRef, {
      credits: (userCredits - 1)
    });

    if (stage1Files.length > 0) {
      const formData = new FormData();
      stage1Files.forEach((file) => {
        formData.append('files', file);  // Use 'files' as the key to send multiple files
      });

      try {
        const response = await axios.post('https://judgeaiv0.azurewebsites.net/dev_stage_1/', formData, {
          // const response = await axios.post('https://judgeaiv0.azurewebsites.net/judgement_stage_1/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setStage1Output(response.data.response);
        setClaimExtractedText(response.data.claim_extracted_text)
        setLegalNormsExtractedText(response.data.legal_norms_extracted_text)
        // setEvidenceExtractedText(response.data.evidence_extracted_text)
        setClaimInfo(response.data.claim_info)
        // console.log(evidenceExtractedText)
        console.log(response.data.stage)
        // console.log(response.data.claim_info)
        setStage1SubmitLoading(false)
        if (response.data.stage === 2) {
          setShowStage2(true)
          // setShowTestButton(true)
          setStage1SubmitDisabled(true);
        }
        scrollToSection("bottom")
      } catch (error) {
        console.error('Error uploading the files', error);
      }
    } else {
      console.log('No files to upload');
    }
    closeAll()
  };

  const handleSubmitStage2 = async () => {
    addToast()
    setStage2SubmitLoading(true);
    const formData = new FormData();
    if (stage2Files.length > 0) {
      stage2Files.forEach((file) => {
        formData.append('files', file);  // Use 'files' as the key to send multiple files
      });
    }
    formData.append('input_string', ("\nPlaintiff Facts and Evidence: \n\n" + stage1Output));  
    try {
      const response = await axios.post('https://judgeaiv0.azurewebsites.net/dev_stage_2/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setStage2Output(response.data.response);
      setDefendantExtractedText(response.data.defendant_extracted_text)
      setResponseInfo(response.data.response_info)
      // setStage2SubmitLoading(false);
      setStage2SubmitDisabled(true);
      // scrollToSection("bottom")
      
      if (response.data.prima_facie_rebutted) {
        setShowStage3(true);
        setStage2SubmitLoading(false)
      } else {
        const stageo_1Input = claimExtractedText + 
                              "\nPlaintiff Facts and Evidence: \n\n" + stage1Output + defendantExtractedText + 
                              "\nDefendant Facts and Evidence: \n\n" + stage2Output
                        + claimantResponseExtractedText + stage3Output + defendantResponseExtractedText + stage4Output;

        const responseo_1 = await axios.post('https://judgeaiv0.azurewebsites.net/judgement_stage_o_1/', stageo_1Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const responseo_1Output = responseo_1.data.response;
        setStageo_1Output(responseo_1Output);


        const stageo_2Input = claimExtractedText + 
                              "\nPlaintiff Facts and Evidence: \n\n" + stage1Output + defendantExtractedText + 
                              "\nDefendant Facts and Evidence: \n\n" + stage2Output
                        + claimantResponseExtractedText + stage3Output + defendantResponseExtractedText + stage4Output +
                        "\nAnalysis: \n\n" + responseo_1Output;

        const responseo_2 = await axios.post('https://judgeaiv0.azurewebsites.net/judgement_stage_o_2/', stageo_2Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const responseo_2Output = responseo_2.data.response;
        setStageo_2Output(responseo_2Output);
        
        const stage4_9Input = claimExtractedText + 
                              "\nPlaintiff Facts and Evidence: \n\n" + stage1Output + defendantExtractedText + 
                              "\nDefendant Facts and Evidence: \n\n" + stage2Output
                        + claimantResponseExtractedText + stage3Output + defendantResponseExtractedText + stage4Output;

        const response4_9 = await axios.post('https://judgeaiv0.azurewebsites.net/dev_stage_4_9/', stage4_9Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const stage4_9Output = response4_9.data.response;
        setEvidenceEvaluationInfo(response4_9.data.evidence_evaluation_info)
        setStage4_9Output(stage4_9Output);
        // scrollToSection("bottom")
        

        const stage4_9_5Input = claimExtractedText + defendantExtractedText
                              + claimantResponseExtractedText + defendantResponseExtractedText + 
                              "\nEvidence Analysis: \n\n" + stage4_9Output;
        const response4_9_5 = await axios.post('https://judgeaiv0.azurewebsites.net/judgement_stage_4_9_5/', stage4_9_5Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const stage4_9_5Output = response4_9_5.data.response;
        setEvidenceLinkageInfo(response4_9_5.data.evidence_linkage_info)
        setStage4_9_5Output(stage4_9_5Output);
        // scrollToSection("bottom")
        

        const stage5Input = "\nPlaintiff Facts and Evidence: \n\n" + stage1Output + 
                            "\nDefendant Facts and Evidence: \n\n" + stage2Output + stage3Output + stage4Output + 
                            "\nEvidence Analysis: \n\n" + stage4_9Output + 
                            "\nEvidence Conclusion: \n\n" + stage4_9_5Output;
        const response5 = await axios.post('https://judgeaiv0.azurewebsites.net/dev_stage_5/', stage5Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const stage5Output = response5.data.response;
        // const stage5Stage = response5.data.stage;
        setCaseFableInfo(response5.data.case_fable_info)
        setStage5Output(response5.data.response);
        // scrollToSection("bottom"

        if (legalNormsExtractedText !== "") {
          const formData5_9 = new FormData();
          formData5_9.append('input_prompt', claimExtractedText);
          formData5_9.append('legal_norms', legalNormsExtractedText);
          const response5_9 = await axios.post('https://judgeaiv0.azurewebsites.net/dev_stage_5_9/', formData5_9, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          const stage5_9Output = response5_9.data.response;
          setStage5_9Output(response5_9.data.response);
          console.log(stage5_9Output)
        }
        // scrollToSection("bottom")

        const stage5_9_5Input = stage5Output;
        const response5_9_5 = await axios.post('https://judgeaiv0.azurewebsites.net/dev_stage_5_9_5/', "\nCase Fable: \n\n" + stage5_9_5Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const stage5_9_5Output = response5_9_5.data.response;
        // const stage6Stage = response6.data.stage;
        setStage5_9_5Output(response5_9_5.data.response);
        // scrollToSection("bottom")

        const stage5_9_6Input = stage5_9_5Output;
        const response5_9_6 = await axios.post('https://judgeaiv0.azurewebsites.net/dev_stage_5_9_6/', '\nPreliminary Ideal Behaviour Model: \n\n' + stage5_9_6Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const stage5_9_6Output = response5_9_6.data.response;
        // const stage6Stage = response6.data.stage;
        setStage5_9_6Output(response5_9_6.data.response);
        // scrollToSection("bottom")
        
        const stage6Input = "\nPlaintiff Facts and Evidence: \n\n" + stage1Output + "\nDefendant Facts and Evidence: \n\n" + stage2Output + stage3Output + stage4Output + "\nCase Fable: \n\n" + stage5Output + stage5_9Output + "\nPreliminary Ideal Behaviour Model Analysis: \n\n" + stage5_9_6Output;
        const response6 = await axios.post('https://judgeaiv0.azurewebsites.net/dev_stage_6/', stage6Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const stage6Output = response6.data.response;
        setIdealChronologyInfo(response6.data.ideal_chronology_info)
        // const stage6Stage = response6.data.stage;
        setShowStage6(true);
        setStage6Output(response6.data.response);
        // scrollToSection("bottom")

        const stage7Input = "\nPlaintiff Facts and Evidence: \n\n" + stage1Output + claimExtractedText +  defendantExtractedText + claimantResponseExtractedText + defendantResponseExtractedText + "\nCase Fable: \n\n" + stage5Output + "\nIdeal Model of Behavior of Parties: \n\n " + stage6Output  + stage5_9Output + "\nAnalysis: \n\n " + responseo_2Output;
        const response7 = await axios.post('https://judgeaiv0.azurewebsites.net/judgement_stage_7_o1/', stage7Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        // const stage7Output = response7.data.response;
        setShowStage7(true);
        setStage7Output(response7.data.response);
        setStage7_1Output(response7.data.judgement_analysis)  
        setStage7_2Output(response7.data.recommendation)
        setStage2SubmitLoading(false);
        // scrollToSection("bottom")

        }
    } catch (error) {
      console.error('Error uploading the files', error);
      setStage2SubmitLoading(false);
    }
    closeAll()
  };

  const handleSubmitStage3 = async () => {
    addToast()
    setStage3SubmitLoading(true)
    if (stage3Files.length > 0) {
      const formData = new FormData();
      stage3Files.forEach((file) => {
        formData.append('files', file);  // Use 'files' as the key to send multiple files
      });
      formData.append('input_string', stage1Output+stage2Output); 
      try {
        const response = await axios.post('https://judgeaiv0.azurewebsites.net/dev_stage_3/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setStage3Output(response.data.response);
        setClaimantResponseExtractedText(response.data.claimant_response_extracted_text)
        setStage3SubmitDisabled(true);
        setShowStage4(true);
        setStage3SubmitLoading(false);
      } catch (error) {
        console.error('Error uploading the files', error);
      }
    } else {
      console.log('No files to upload');
    }
    closeAll()
  };

  const handleSubmitStage3NoObjection = async () => {
    setStage3SubmitLoading(true)
    // setStage3Output("\nClaimant clarification to defendant's response: \nNo objection and no response from claimant");
    // setClaimantResponseExtractedText("\nClaimant clarification to defendant's response: \nNo objection and no response from claimant");
    setStage3SubmitDisabled(true);
    setShowStage4(true);
    setStage3SubmitLoading(false);
  };

  const handleSubmitStage4 = async () => {
    addToast()
    setStage4SubmitLoading(true);
  
    if (stage4Files.length > 0) {
      const formData = new FormData();
      stage4Files.forEach((file) => {
        formData.append('files', file);  // Use 'files' as the key to send multiple files
      });
      formData.append('input_string', stage1Output + stage2Output + stage3Output);
  
      const response = await axios.post('https://judgeaiv0.azurewebsites.net/dev_stage_4/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const stage4Output = response.data.response;
      const defendantResponseExtractedText = response.data.defendant_response_extracted_text;
      setStage4Output(stage4Output);
      setDefendantResponseExtractedText(defendantResponseExtractedText);
      setStage4SubmitDisabled(true);
    }
    else {
      // const stage4Output = "\nDefendant's response to claimant's clarifications: \nNo objection and no response from defendant";
      // const defendantResponseExtractedText = "\nDefendant's response to claimant's clarifications: \nNo objection and no response from defendant";
      // setStage4Output(stage4Output);
      // setDefendantResponseExtractedText(defendantResponseExtractedText);
      setStage4SubmitDisabled(true);
    }

    const stageo_1Input = claimExtractedText + 
                              "\nPlaintiff Facts and Evidence: \n\n" + stage1Output + defendantExtractedText + 
                              "\nDefendant Facts and Evidence: \n\n" + stage2Output
                        + claimantResponseExtractedText + stage3Output + defendantResponseExtractedText + stage4Output;

        const responseo_1 = await axios.post('https://judgeaiv0.azurewebsites.net/judgement_stage_o_1/', stageo_1Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const responseo_1Output = responseo_1.data.response;
        setStageo_1Output(responseo_1Output);


        const stageo_2Input = claimExtractedText + 
                              "\nPlaintiff Facts and Evidence: \n\n" + stage1Output + defendantExtractedText + 
                              "\nDefendant Facts and Evidence: \n\n" + stage2Output
                        + claimantResponseExtractedText + stage3Output + defendantResponseExtractedText + stage4Output +
                        "\nAnalysis: \n\n" + responseo_1Output;

        const responseo_2 = await axios.post('https://judgeaiv0.azurewebsites.net/judgement_stage_o_2/', stageo_2Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const responseo_2Output = responseo_2.data.response;
        setStageo_2Output(responseo_2Output);

    const stage4_9Input = claimExtractedText + 
                              "\nPlaintiff Facts and Evidence: \n\n" + stage1Output + defendantExtractedText + 
                              "\nDefendant Facts and Evidence: \n\n" + stage2Output
                        + claimantResponseExtractedText + stage3Output + defendantResponseExtractedText + stage4Output;

    const response4_9 = await axios.post('https://judgeaiv0.azurewebsites.net/dev_stage_4_9/', stage4_9Input, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const stage4_9Output = response4_9.data.response;
    setEvidenceEvaluationInfo(response4_9.data.evidence_evaluation_info)
    setStage4_9Output(stage4_9Output);

    const stage4_9_5Input = claimExtractedText + defendantExtractedText
                              + claimantResponseExtractedText + defendantResponseExtractedText + 
                              "\nEvidence Analysis: \n\n" + stage4_9Output;
    const response4_9_5 = await axios.post('https://judgeaiv0.azurewebsites.net/judgement_stage_4_9_5/', stage4_9_5Input, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const stage4_9_5Output = response4_9_5.data.response;
    setEvidenceLinkageInfo(response4_9_5.data.evidence_linkage_info)
    setStage4_9_5Output(stage4_9_5Output);

    const stage5Input = "\nPlaintiff Facts and Evidence: \n\n" + stage1Output + 
                        "\nDefendant Facts and Evidence: \n\n" + stage2Output + stage3Output + stage4Output + 
                        "\nEvidence Analysis: \n\n" + stage4_9Output + 
                        "\nEvidence Conclusion: \n\n" + stage4_9_5Output;
    const response5 = await axios.post('https://judgeaiv0.azurewebsites.net/dev_stage_5/', stage5Input, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const stage5Output = response5.data.response;
    setCaseFableInfo(response5.data.case_fable_info)
    // const stage5Stage = response5.data.stage;
    setStage5Output(response5.data.response);

    if (legalNormsExtractedText !== "") {
      const formData5_9 = new FormData();
      formData5_9.append('input_prompt', claimExtractedText);
      formData5_9.append('legal_norms', legalNormsExtractedText);
      const response5_9 = await axios.post('https://judgeaiv0.azurewebsites.net/dev_stage_5_9/', formData5_9, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const stage5_9Output = response5_9.data.response;
      setStage5_9Output(response5_9.data.response);
      console.log(stage5_9Output)
    }

    const stage5_9_5Input = "\nCase Fable: \n\n" + stage5Output;
    const response5_9_5 = await axios.post('https://judgeaiv0.azurewebsites.net/dev_stage_5_9_5/', stage5_9_5Input, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const stage5_9_5Output = response5_9_5.data.response;
    // const stage6Stage = response6.data.stage;
    setStage5_9_5Output(response5_9_5.data.response);
    // scrollToSection("bottom")

    const stage5_9_6Input = stage5_9_5Output;
        const response5_9_6 = await axios.post('https://judgeaiv0.azurewebsites.net/dev_stage_5_9_6/', stage5_9_6Input, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const stage5_9_6Output = response5_9_6.data.response;
        setStage5_9_6Output(response5_9_6.data.response);

    const stage6Input = "\nPlaintiff Facts and Evidence: \n\n" + stage1Output + "\nDefendant Facts and Evidence: \n\n" + stage2Output + stage3Output + stage4Output + "\nCase Fable: \n\n" + stage5Output + stage5_9Output + "\nPreliminary Ideal Behaviour Model Analysis: \n\n" + stage5_9_6Output;
    const response6 = await axios.post('https://judgeaiv0.azurewebsites.net/dev_stage_6/', stage6Input, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const stage6Output = response6.data.response;
    setIdealChronologyInfo(response6.data.ideal_chronology_info)
    // const stage6Stage = response6.data.stage;
    setShowStage6(true);
    setStage6Output(response6.data.response);

    const stage7Input = "\nPlaintiff Facts and Evidence: \n\n" + stage1Output + claimExtractedText +  defendantExtractedText + claimantResponseExtractedText + defendantResponseExtractedText + "\nCase Fable: \n\n" + stage5Output + "\nIdeal Model of Behavior of Parties: \n\n " + stage6Output  + stage5_9Output + "\nAnalysis: \n\n " + responseo_2Output;
    const response7 = await axios.post('https://judgeaiv0.azurewebsites.net/judgement_stage_7_o1/', stage7Input, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // const stage7Output = response7.data.response;
    setShowStage7(true);
    setStage7Output(response7.data.response);
    setStage7_1Output(response7.data.judgement_analysis)
    setStage7_2Output(response7.data.recommendation)  
    setStage4SubmitLoading(false);
    closeAll()  
  };


  return (
    <ChakraProvider theme={theme}>
      
      <Navbar />
      <GuideButton guideUrl="/how_to_use" />
      <Box paddingTop={"40px"} 
      paddingBottom={"40px"} 
      // border={"2px solid red"}
      >
        <VStack spacing={"40px"} 
        // border={"2px solid red"}
        >
        {(userCredits === 0) && (!loadingScreen) && (<Box>
          {/* <Alert status='error'>
            <AlertIcon />
            Please purchase credits to use JudgeAI.
          </Alert> */}
          <Alert
          status='error'
          variant='subtle'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          height='200px'
          borderRadius={"10px"}
        >
          <AlertIcon boxSize='40px' mr={0} />
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            Out of Credits!
          </AlertTitle>
          <AlertDescription maxWidth='sm'>
            Please purchase credits to use JudgeAI.
          </AlertDescription>
        </Alert>
        </Box>)}
        {(userCredits > 0) && (<Box>
          <VStack spacing={"10px"}>
          <UploadButton onFilesChange={handleStage1FilesChange} isDisabled={(stage1SubmitDisabled || stage1SubmitLoading)} uploading={stage1FileUploading} />
          <Button colorScheme="teal" onClick={handleSubmitStage1} isDisabled={stage1SubmitDisabled} isLoading={stage1SubmitLoading}>
            Submit
          </Button>
          </VStack>
        </Box>)}
          {/* <Box>
          <VStack spacing={"10px"}>
          <UploadButton onFilesChange={handleStage1FilesChange} isDisabled={(stage1SubmitDisabled || stage1SubmitLoading)} />
          <Button colorScheme="teal" onClick={handleSubmitStage1} isDisabled={stage1SubmitDisabled} isLoading={stage1SubmitLoading}>
            Submit
          </Button>
          </VStack>
          </Box> */}
          {stage1Output && (
            <>
            <Box
              p={10}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"2xl"} mb={4}>Facts extracted from claim</Heading>
              <Heading mb={2} size={"md"} color="teal">At this stage, information is extracted from the Plaintiff's documents for further analysis</Heading>
              {/* <Markdown>{stage1Output}</Markdown> */}
              <ReactMarkdown components={ChakraUIRenderer()} children={stage1Output} skipHtml />;
              <Heading size={"md"} mt={2} color="teal">To continue, please attach the plaintiff's documents</Heading>
            </Box>
            </>
          )}
          {showStage2 && (
            <Box
            mt={4}
            >
              <VStack spacing={"10px"}>
                <UploadButton onFilesChange={handleStage2FilesChange} 
                              message="Defendant is directed to upload response and evidence" 
                              isDisabled = {stage2SubmitDisabled || stage2SubmitLoading}
                              uploading={stage2FileUploading}
                              />
                <Button colorScheme="teal" onClick={handleSubmitStage2} isDisabled={stage2SubmitDisabled} isLoading={stage2SubmitLoading}>
                  Submit
                </Button>
              </VStack>
            </Box>
          )}
          {stage2Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"2xl"} mb={4}>Facts extracted from defendant's response</Heading>
              <Heading  size={"md"} color="teal" mb={2}>At this stage, information is extracted from the Defendant's documents for further analysis.</Heading>
              {/* <Markdown>{stage2Output}</Markdown> */}
              <ReactMarkdown components={ChakraUIRenderer()} children={stage2Output} skipHtml />;
            </Box>
            </>
          )}
          {showStage3 && (
            <Box
            mt={4}
            >
              <VStack spacing={"10px"}>
                <UploadButton onFilesChange={handleStage3FilesChange} 
                              message="Claimant is directed to give response" 
                              isDisabled = {stage3SubmitDisabled || stage3SubmitLoading}
                              uploading={stage3FileUploading}
                              />
                <Button colorScheme="teal" onClick={handleSubmitStage3} isDisabled={stage3SubmitDisabled} isLoading={stage3SubmitLoading}>
                  Submit
                </Button>
                <Text>Or</Text>
                <Button colorScheme="red" onClick={handleSubmitStage3NoObjection} isDisabled={stage3SubmitDisabled} isLoading={stage3SubmitLoading}>
                  No objection
                </Button>
              </VStack>
            </Box>
          )}
          {stage3Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Facts extracted from claimant's clarification</Heading>
              <Heading  size={"md"} color="teal" mb={2}>At this stage, information is extracted from the Plaintiff's additional documents for further analysis.</Heading>
              <Markdown>{stage3Output}</Markdown>
            </Box>
            </>
          )}
          {showStage4 && (
            <Box
            mt={4}
            >
              <VStack spacing={"10px"}>
                <UploadButton onFilesChange={handleStage4FilesChange} isDisabled={stage4SubmitDisabled} message="Respondant is directed to give response" uploading={stage4FileUploading} />
                <Button colorScheme="teal" onClick={handleSubmitStage4} isDisabled={stage4SubmitDisabled} isLoading={stage4SubmitLoading}>
                  Submit
                </Button>
                <Text>Or</Text>
                <Button colorScheme="red" onClick={handleSubmitStage4} isDisabled={stage4SubmitDisabled} isLoading={stage4SubmitLoading}>
                  No objection
                </Button>
              </VStack>
            </Box>
          )}
          {stage4Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"lg"} mb={4}>Facts extracted from defendant's clarification</Heading>
              <Heading  size={"md"} color="teal" mb={2}>At this stage, information is extracted from the Defendant's additional documents for further analysis.</Heading>
              <Markdown>{stage4Output}</Markdown>
            </Box>
            </>
          )}
          {stageo_1Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"2xl"} mb={4}>Defining Regulatory Criteria</Heading>
              {/* <Markdown>{stage4_9Output}</Markdown> */}
              <ReactMarkdown components={ChakraUIRenderer()} children={stageo_1Output} skipHtml />;
            </Box>
            </>
          )}
          {stageo_2Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"2xl"} mb={4}>Generation of Legal Norms</Heading>
              {/* <Markdown>{stage4_9Output}</Markdown> */}
              <ReactMarkdown components={ChakraUIRenderer()} children={stageo_2Output} skipHtml />;
            </Box>
            </>
          )}
          {stage4_9Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"2xl"} mb={4}>Evidence Analysis</Heading>
              <Heading  size={"md"} color="teal" mb={2}>At this stage, pairs of conditionally contradictory evidence are created (in reality, the evidence may not actually contradict each other), and each piece of evidence is assigned a strength indicator: weak, medium, or strong. This is necessary for JudgeAI to further consider the case.</Heading>
              {/* <Markdown>{stage4_9Output}</Markdown> */}
              <ReactMarkdown components={ChakraUIRenderer()} children={stage4_9Output} skipHtml />;
            </Box>
            </>
          )}
          {stage4_9_5Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"2xl"} mb={4}>Evidence Conclusion</Heading>
              <Heading  size={"md"} color="teal" mb={2}>At this stage, each pair of conditionally contradictory evidence is analyzed to determine whether the evidence actually contradicts each other. If they do not contradict, the two pieces of evidence are synthesized. If they do contradict, the stronger evidence is selected. This is necessary for JudgeAI to further consider the case.</Heading>
              {/* <Markdown>{stage4_9_5Output}</Markdown> */}
              <ReactMarkdown components={ChakraUIRenderer()} children={stage4_9_5Output} skipHtml />;
            </Box>
            </>
          )}
          {stage5Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"2xl"} mb={4}>Case Fable</Heading>
              <Heading  size={"md"} color="teal" mb={2}>At this stage, a chronology of the parties' actual behavior within the case is formed based on the selected evidence. This is necessary for JudgeAI to further consider the case.</Heading>
              {/* <Markdown>{stage5Output}</Markdown> */}
              <ReactMarkdown components={ChakraUIRenderer()} children={stage5Output} skipHtml />;
            </Box>
            </>
          )}
          {stage5_9Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"2xl"} mb={4}>Relevant Legal Norms</Heading>
              <Markdown>{stage5_9Output}</Markdown>
            </Box>
            </>
          )}
          {stage5_9_5Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"2xl"} mb={4}>Preliminary Ideal Behaviour Model</Heading>
              <Heading  size={"md"} color="teal" mb={2}>At this stage, JudgeAI begins forming an ideal model of the parties' behavior—that is, how the parties should have acted if they had behaved in good faith and reasonably. Specifically, this stage establishes facts whose presence or absence could affect the fulfillment of the parties' obligations..</Heading>
              {/* <Markdown>{stage5_9_5Output}</Markdown> */}
              <ReactMarkdown components={ChakraUIRenderer()} children={stage5_9_5Output} skipHtml />;
            </Box>
            </>
          )}
          {stage5_9_6Output && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"2xl"} mb={4}>Preliminary Ideal Behaviour Model Analysis</Heading>
              <Heading  size={"md"} color="teal" mb={2}>Continuing to form the ideal model of the parties' behavior, JudgeAI establishes possible scenarios of the parties' behavior, ranging from the least good faith to the most altruistic. Mathematical values are assigned to these scenarios for further analysis.</Heading>
              {/* <Markdown>{stage5_9_6Output}</Markdown> */}
              <ReactMarkdown components={ChakraUIRenderer()} children={stage5_9_6Output} skipHtml />;
            </Box>
            </>
          )}
          {showStage6 && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"2xl"} mb={4}>Ideal chronology</Heading>
              <Heading  size={"md"} color="teal" mb={2}>At this stage, JudgeAI completes forming the ideal model of the parties' behavior—that is, how the parties should have acted if they had behaved in good faith and reasonably. This is for further comparison of the ideal model with the actual behavior of the parties</Heading>
              {/* <Markdown>{stage6Output}</Markdown> */}
              <ReactMarkdown components={ChakraUIRenderer()} children={stage6Output} skipHtml />;
            </Box>
            </>
          )}
          {showStage7 && (
            <>
            <Box
              p={10}
              // mt={4}
              borderWidth="1px"
              borderRadius="lg"
              width="70vw"
            >
              <Heading size={"2xl"} mb={4}>Final Judgement</Heading>
              {/* <Markdown>{stage7Output}</Markdown> */}
              <ReactMarkdown components={ChakraUIRenderer()} children={stage7Output} skipHtml />;
            </Box>
            <Button colorScheme="blue" onClick={generateReport} isLoading={downloadReportSubmitLoading}>Download Report</Button>
            </>
          )}
        </VStack>
        <div id="bottom">
        </div>
        
      </Box>
      <Progress
        value={progress}
        // size="lg"
        height = "22px"
        // colorScheme="teal"
        position="fixed"
        bottom="0"
        left="0"
        width="100%"
      />
    </ChakraProvider>
  );
};

export default JudgementPage;