import { useState, useEffect, ReactNode } from 'react';
import {
  Text,
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Image,
  LinkBox,
  LinkOverlay,
  Skeleton,
  SkeletonCircle,
  Divider
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
// import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useUserAuth } from '../context/userAuthContext';
import { db } from '../firebase';
import { doc, getDoc } from "firebase/firestore";

const Links = [
  { name: 'Sign in', to: '/login' },
  { name: 'Sign up', to: '/signup' }
];

const NavLink = ({ children, href }: { children: ReactNode, href: string }) => (
  <Link
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    as={RouterLink}
    to={href}
  >
    {children}
  </Link>
);

export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [userCredits, setUserCredits] = useState("");
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { logOut, user } = useUserAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) { 
        const userEmail = user.email || '';
        
        try {
          const userDoc = await getDoc(doc(db, "users", userEmail));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserCredits(userData.credits || 0);
            setUserName((userData.firstName + ' ' + userData.lastName) || "");
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserData();
  }, [user]);

  const handleSignOut = async () => {
    await logOut();
    navigate("/");
  };

  const goToProfilepage = async () => {
    navigate("/profile");
  }

  return (
    <>
      <Box
        bg={useColorModeValue('gray.100', 'gray.900')}
        px={4}
        borderRadius="lg"
      >
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          {!user && (
            <IconButton
              size={'md'}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={'Open Menu'}
              display={{ md: 'none' }}
              onClick={isOpen ? onClose : onOpen}
            />
          )}
          <HStack spacing={8} alignItems={'center'}>
            <Box>
              <LinkBox>
                <LinkOverlay as={RouterLink} to='/'>
                  <Image src={useColorModeValue("navbar_logo.png", "navbar_logo_darkmode.png")} width={"120px"} />
                </LinkOverlay>
              </LinkBox>
            </Box>
          </HStack>

          {/* Divider with Demo 1 and Demo 2 buttons */}
          {/* <Flex alignItems={'center'} justifyContent={'center'} display={{ base: 'none', md: 'flex' }}>
            <Button as={RouterLink} to='/judgement_demo' colorScheme='black' variant={"ghost"}>
              Demo Version
            </Button>
            <Divider orientation="vertical" height="40px" mx={4} borderColor={useColorModeValue('gray.900', 'gray.200')} />
            <Button as={RouterLink} to='/test_cases' colorScheme='black' variant={"ghost"}>
              Test Cases
            </Button>
          </Flex> */}

<Flex alignItems={'center'} justifyContent={'center'}>
  {/* For larger screens */}
  <Flex display={{ base: 'none', md: 'flex' }} alignItems={'center'}>
    <Button as={RouterLink} to='/judgement_demo' colorScheme='black' variant={"ghost"}>
      Demo Version
    </Button>
    <Divider orientation="vertical" height="40px" mx={4} borderColor={useColorModeValue('gray.900', 'gray.200')} />
    <Button as={RouterLink} to='/test_cases' colorScheme='black' variant={"ghost"}>
      Test Cases
    </Button>
    <Divider orientation="vertical" height="40px" mx={4} borderColor={useColorModeValue('gray.900', 'gray.200')} />
    <Button as={RouterLink} to='/use_cases' colorScheme='black' variant={"ghost"}>
      Use Cases
    </Button>
    <Divider orientation="vertical" height="40px" mx={4} borderColor={useColorModeValue('gray.900', 'gray.200')} />
    <Button as={RouterLink} to='/docs' colorScheme='black' variant={"ghost"}>
    Documentation
    </Button>
    <Divider orientation="vertical" height="40px" mx={4} borderColor={useColorModeValue('gray.900', 'gray.200')} />
    <Button as={RouterLink} to='/how_to_use' colorScheme='black' variant={"ghost"}>
    Guide
    </Button>
  </Flex>

  {/* For mobile screens - Hamburger Menu */}
  <Menu>
    <MenuButton
      as={IconButton}
      aria-label='Options'
      icon={<HamburgerIcon />}
      display={{ base: 'flex', md: 'none' }}
      variant='outline'
    />
    <MenuList>
      <MenuItem as={RouterLink} to='/judgement_demo'>
        Demo Version
      </MenuItem>
      <MenuItem as={RouterLink} to='/test_cases'>
        Test Cases
      </MenuItem>
      <MenuItem as={RouterLink} to='/use_cases'>
        Use Cases
      </MenuItem>
      <MenuItem as={RouterLink} to='/docs'>
        Documentation
      </MenuItem>
      <MenuItem as={RouterLink} to='/how_to_use'>
        Guide
      </MenuItem>
    </MenuList>
  </Menu>
</Flex>



          <Flex alignItems={'center'}>
            <HStack spacing={{ base: '5', md: '24px' }}>
              {/* <ColorModeSwitcher /> */}
              {user ? (
                <>
                  {loading ? (
                    <Skeleton height="20px" width="60px">
                      <Text>Credits:</Text>
                    </Skeleton>
                  ) : (
                    <Text>Credits: {userCredits}</Text>
                  )}
                  <Menu>
                    <MenuButton
                      as={Button}
                      rounded={'full'}
                      variant={'link'}
                      cursor={'pointer'}
                      minW={0}>
                      {loading ? (
                        <SkeletonCircle height="40px" width="40px" />
                      ) : (
                        <Avatar
                          size={'sm'}
                          name={userName}
                          src={'https://bit.ly/broken-link'}
                        />
                      )}
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={goToProfilepage}>Profile</MenuItem>
                      {/* <MenuItem onClick={goToProfilepage}>Documentation</MenuItem> */}
                      <MenuDivider />
                      <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                    </MenuList>
                  </Menu>
                </>
              ) : (
                <Stack
                  flex={{ base: 1, md: 0 }}
                  justify={'flex-end'}
                  direction={'row'}
                  spacing={6}>
                  <Button
                    as={RouterLink}
                    display={{ base: 'none', md: 'inline-flex' }}
                    fontSize={'sm'}
                    fontWeight={600}
                    color={'white'}
                    bg={'blue.400'}
                    to={'/login'}
                    _hover={{
                      bg: 'blue.300',
                    }}>
                    Sign In
                  </Button>
                  <Button
                    as={RouterLink}
                    display={{ base: 'none', md: 'inline-flex' }}
                    fontSize={'sm'}
                    fontWeight={600}
                    color={'white'}
                    bg={'pink.400'}
                    to={'/signup'}
                    _hover={{
                      bg: 'pink.300',
                    }}>
                    Sign Up
                  </Button>
                </Stack>
              )}
            </HStack>
          </Flex>
        </Flex>

        {isOpen && !user && (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link.name} href={link.to}>{link.name}</NavLink>
              ))}
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );
}
