import { useEffect } from "react";
import {
  ChakraProvider,
  Heading,
  Box,
  Text,
  Image,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
// import { GrDocumentText } from "react-icons/gr";
import ReactGA from 'react-ga4';

export const HowToUseSimplePage = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/test_cases", title: "TestCasesPage" });
  }, []);

  return (
    <ChakraProvider>
        <Navbar />
      <Box 
        p={4} 
        width={{sm:"95vw", md:"70vw"}} 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        // alignItems="center" 
        mx="auto" 
        mt={10}
        // border={"2px solid red"}
      >
        <Heading 
          as="h1" 
          size="4xl" 
          color="teal" 
          textAlign="center" 
          mb={{ base: 20, md: 20 }}
        >
          How to Use Simple Version of JudgeAI
        </Heading>
        <Text fontSize="xl">
          Follow these easy steps to conduct arbitration with JudgeAI.
        </Text>

        <Heading size={"md"} mt={4}>
  1. Start Simple Arbitration
</Heading>
<Text fontSize="xl">
1.Click <Text as='b'>"Try Now"</Text> button, and a section for uploading your claim will appear.
</Text>
<Image src='guide/simple_1.gif' alt='Dan Abramov' width={{sm:"95vw", md:"50vw"}} mb={10} mt={5}/>

<Heading size={"md"} mt={4}>
  2. Enter Your Claim
</Heading>
<Text fontSize="xl">
  Provide a clear description of your claim in the designated field. You can also submit supporting documents. After uploading, click <Text as='b'>"Submit"</Text> to complete the process.
</Text>
<Image src='guide/simple_2.gif' alt='Dan Abramov' width={{sm:"95vw", md:"50vw"}} mb={10} mt={5}/>

<Heading size={"md"} mt={4}>
  3. Add the Defendant's Position
</Heading>
<Text fontSize="xl">
  Enter the defendant's position in the next field. You can also submit supporting documents. After uploading, click <Text as='b'>"Submit"</Text> to complete the process.
</Text>
<Image src='guide/simple_3.gif' alt='Dan Abramov' width={{sm:"95vw", md:"50vw"}} mb={10} mt={5}/>

<Heading size={"md"} mt={4}>
  4. Final Step
</Heading>
<Text fontSize="xl">
  Once all information is submitted and analyzed, JudgeAI will finalize its decision based on the provided details.
</Text>
<Image src='guide/simple_4.gif' alt='Dan Abramov' width={{sm:"95vw", md:"50vw"}} mb={10} mt={5}/>

      </Box>
    </ChakraProvider>
  );
}

export default HowToUseSimplePage;
